<template>
  <div class="calc_claves" v-if="record">
    <div class="cab blue-grey lighten-2">
      {{ this.titulo_claves[this.id_titulo] }}
      <v-btn icon @click="change_view()">
        <v-icon small> {{ "mdi-arrow-right" }} </v-icon>
      </v-btn>
    </div>

    <v-input_n
      v-bind="$input"
      v-model="record.Aimp[0][id_titulo]"
      label="Servicio"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.Aimp[1][id_titulo]"
      label="Nicho"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.Aimp[2][id_titulo]"
      label="Incineración"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.Aimp[3][id_titulo]"
      label="Tanatorio"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.Aimp[4][id_titulo]"
      label="Lápida"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.T_suma"
      label="Suma"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.Aimp[5][id_titulo]"
      label="Recog. Jud."
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.Aimp[6][id_titulo]"
      label="Traslados"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.T_pm"
      label="Post Mortem"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.T_traslado"
      label="Traslados"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.T_capital"
      label="T. capital"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.T_servicio"
      label="T. servicio"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.diferencia"
      label="Diferencia"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>

    <v-input_n
      v-bind="$input"
      v-model="record.complemento"
      label="Complementos"
      readonly
      :disabled="!edicion"
    >
    </v-input_n>
  </div>
</template>

<script>
export default {
  props: {
    record: { type: Object, default: null },
    edicion: { type: Boolean, default: false }
  },

  data() {
    return {
      titulo_claves: ["CAPITALES", "SERVICIO", "DIFERENCIAS"],
      id_titulo: 0,
      /* Aimp:[],
                T_suma:0,
                T_pm:0,
                T_traslado:0,
                T_capital:0,
		        T_servicio:0,
                complemento:0,
                diferencia:0, */
      Aclaves: [
        { grupo: "Servicio", claves: ["XJ", "FR", "CD"] },
        { grupo: "Nicho", claves: ["YA"] },
        { grupo: "Incineracion", claves: ["YJ"] },
        { grupo: "Tanatorio", claves: ["YC"] },
        { grupo: "Lapida", claves: ["YB"] },
        { grupo: "Recog. Jud.", claves: ["YW"] },
        { grupo: "Traslados", claves: ["XP", "YH", "XY"] }
      ]
    };
  },

  created() {
    //this.reset();
  },

  methods: {
    //
    async ini(records) {
      if (typeof this.schema["servpm"] === "undefined") this.id_titulo = 1;

      await this.capitales();
      await this.calculo(records);
    },

    // reseteo array de importes
    /* reset()
            {
                this.Aimp= [];

                for (let x=0; x< this.Aclaves.length; x++) {
                    this.Aimp[x]= [0,0,0];
                }
            }, */

    //
    /* capitales() 
            {
                if (typeof this.schema['servpm']=== 'undefined') return;
                                
                this.T_capital= 0;
                this.T_servicio= 0;
                                
                // guardo capitales en el array general
                this.Aimp[0][0]= Number(this.schema.servpm);
                this.Aimp[1][0]= Number(this.schema.servnicho);
                this.Aimp[2][0]= Number(this.schema.servincinera);
                this.Aimp[3][0]= Number(this.schema.servlapida);
                this.Aimp[4][0]= Number(this.schema.servtana);

                // SUMA DE CAPITALES
                this.T_capital= (this.Aimp[0][0]+this.Aimp[1][0]+this.Aimp[2][0]+this.Aimp[3][0]+this.Aimp[4][0]).toFixed(2);

                // SUMA DE SERVICIO no incluye recog judicial ni traslado
                this.Aimp[6][0]= 0;

                // SERVICIO
                this.T_servicio= (this.Aimp[6][0]).toFixed(2);

                // DIFERENCIA
                this.diferencia= (this.T_capital - this.Aimp[6][0]).toFixed(2);

                // COMPLEMENTO
                this.complemento= this.schema.servcomplemento;
		    }, */

    //
    calculo(records) {
      var iva;
      var imp;

      // inicializo variables totales
      this.T_suma = this.T_pm = this.T_traslado = 0;

      // Inicializo claves de diferencias igualandolas con los capitales
      this.Aimp[0][2] = this.Aimp[0][0];
      this.Aimp[1][2] = this.Aimp[1][0];
      this.Aimp[2][2] = this.Aimp[2][0];
      this.Aimp[3][2] = this.Aimp[3][0];
      this.Aimp[4][2] = this.Aimp[4][0];

      // Inicializo acumulados de claves servicio
      for (let i = 0; i < this.Aclaves.length; i++) {
        this.Aimp[i][1] = 0;
      }

      //
      for (const key in records) {
        const item = records[key];

        imp = Number(item.imp);
        iva = Number(item.iva);
        imp += imp * (iva / 100);

        if (item.grup == "P") {
          this.T_pm += imp;
        } else {
          this.T_traslado += imp;
        }

        // -- acumula clave
        for (let i = 0; i < this.Aclaves.length; i++) {
          if (this.Aclaves[i].claves.indexOf(item.clave) > -1) {
            this.Aimp[i][1] += Number(imp);
            if (i < 5) this.Aimp[i][2] = this.Aimp[i][0] - this.Aimp[i][1];
          }
        }
      }

      // SUMA DE SERVICIO (sin traslado y recojida judicial)
      this.T_suma = (
        this.Aimp[0][1] +
        this.Aimp[1][1] +
        this.Aimp[2][1] +
        this.Aimp[3][1] +
        this.Aimp[4][1]
      ).toFixed(2);

      // redondeo cantidades a 2 decimales
      this.T_pm = this.T_pm.toFixed(2);
      this.T_traslado = this.T_traslado.toFixed(2);
      for (let i = 0; i < this.Aclaves.length; i++) {
        this.Aimp[i][1] = this.Aimp[i][1].toFixed(2);
        if (i < 5) this.Aimp[i][2] = this.Aimp[i][2].toFixed(2);
      }
    },

    // muestro "CAPITALES", "SERVICIO" ó "DIFERENCIAS"
    change_view() {
      this.id_titulo =
        this.id_titulo >= this.titulo_claves.length - 1
          ? 0
          : this.id_titulo + 1;
    }
  }
};
</script>
